<template>
  <div class="page">
    <Head />
    <Sidebar />
    <div class="body-main">
      <breadInfo />
      <div class="container bg flex-1 flex-v">
        <div class="pub-tt m15">
          <h5 class="bt">
            <img
              src="../assets/images/equip_01.png"
              class="ico"
              alt=""
            />设备监控服务
          </h5>
          <div class="dix"></div>
        </div>

        <div class="flex-1 flex-v p025 scrollybg">
          <ul class="list">
            <li
              class="item"
              v-for="(item, index) in equipData"
              :key="index"
              @click="showRealInfo(item.id)"
            >
              <div class="lbox">
                <div class="imgbox">
                  <img :src="item.pic" alt="" class="img img_full" />
                  <span class="tag">{{
                    item.status == "1" ? "有效" : "无效"
                  }}</span>
                </div>
              </div>
              <div class="rbox">
                <div class="col">
                  <h6 class="title">{{ item.name }}</h6>
                  <div class="txt">
                    <p>品牌：{{ item.brand }}</p>
                    <p>出厂日期：{{ item.productionDate }}</p>
                    <p>型号#：{{ item.model }}</p>
                    <p>设备类型：{{ item.powerCategoryName }}</p>
                    <p>功率：{{ item.power }}</p>
                    <p>其他设备信息：{{ item.otherMsg }}</p>
                  </div>
                </div>
                <div class="col">
                  <h6 class="title">
                    瞬间效率 <em class="c-main">{{ getRealTimeInfo(item.id) }}</em
                    >（kWh/kWh）
                  </h6>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Head from "@/components/head.vue";
import Sidebar from "@/components/sidebar.vue";
import breadInfo from "@/components/breadInfo.vue";
import { equipList, getRealInfo } from "@/api/equip";
export default {
  data() {
    return {
      pageInfo: {
        total: 0,
        pageSize: 10,
        pageNum: 1,
      },
      loading: false,
      websocket: null,
      equipData: [],
      equipInfo: [],
      equipValue: "",
    };
  },
  components: {
    Head,
    Sidebar,
    breadInfo,
  },
  created() {
    this.getEquipList();
  },
  methods: {
    //获取设备列表
    getEquipList() {
      this.loading = true;
      let param = {
        pageNum: this.pageInfo.pageNum,
        pageSize: this.pageInfo.pageSize,
        projectId: Number(sessionStorage.getItem("projectId")),
        projectName: sessionStorage.getItem("projectName"),
        tenantId: JSON.parse(sessionStorage.getItem("user")).tenantId,
      };
      equipList(param).then((res) => {
        if (res.data.code == 0) {
          this.equipData = res.data.rows;
        } else {
          this.$message.warning(res.data.msg);
        }
        this.loading = false;
      });
    },    
    getRealTimeInfo(id) {
      let param = {
        deviceId: id,
      };
      let equipValue = "";
      getRealInfo(param).then((res) => {
        if (res.data.code == 0) {
          sessionStorage.setItem("equipValue", res.data.rows[1].value);
        }
      });
      equipValue = sessionStorage.getItem("equipValue")
      return equipValue;
    },
  },
};
</script>

<style scoped>
.list .item {
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  background: url(../assets/images/equip_14.png) no-repeat center;
  background-size: 100% 100%;
}

.list .item:last-child {
  margin-bottom: 0;
}

.list .lbox {
  width: 540px;
  margin-right: 40px;
  position: relative;
}

.list .imgbox {
  height: 355px;
}

.list .tag {
  font-size: 14px;
  color: #00ff9c;
  border: 1px solid #00ff9c;
  display: inline-block;
  vertical-align: middle;
  line-height: 22px;
  padding: 0 6px;
  position: absolute;
  right: 20px;
  top: 20px;
}

.list .rbox {
  display: flex;
  position: relative;
  flex: 1;
  overflow: hidden;
}

.list .col {
  min-width: 250px;
  margin-right: 20px;
}

.list .title {
  font-size: 20px;
  color: #fff;
  font-weight: bold;
  line-height: 1.8;
  margin-bottom: 6px;
}

.list .txt {
  font-size: 14px;
  color: #bcc8d4;
  line-height: 32px;
}

.list .btns {
  position: absolute;
  right: 10px;
  bottom: 10px;
  display: flex;
}

.list .anniu {
  min-width: 135px;
  height: 50px;
  margin-left: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  border: none;
  cursor: pointer;
}

.list .anniu::before {
  content: "";
  display: inline-block;
  margin-right: 3px;
  width: 22px;
  height: 22px;
}

.list .anniu1 {
  background: url(../assets/images/equip_12.png) no-repeat center;
  background-size: 100% 100%;
}

.list .anniu1::before {
  background: url(../assets/images/equip_10.png) no-repeat center;
  background-size: contain;
}

.list .anniu2 {
  background: url(../assets/images/equip_13.png) no-repeat center;
  background-size: 100% 100%;
}

.list .anniu2::before {
  background: url(../assets/images/equip_11.png) no-repeat center;
  background-size: contain;
}

.list .anniu:hover {
  opacity: 0.85;
}

.list .zt {
  font-size: 14px;
  color: #00ff9c;
  border: 1px solid #00ff9c;
  display: inline-block;
  vertical-align: middle;
  line-height: 22px;
  padding: 0 6px;
}
</style>
